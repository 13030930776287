
<template>
  <div class="promotion-page"> 
    <div class="top-section">
      <div class="logo"><img src="../assets/a10.png" class="logoimg" /></div>
      <div class="right-section">
        <div class="contact-info">
          <a href="https://t.me/ming1314bot" style="  text-decoration: none; color: #ffffff;">
            <img src="../assets/sw.png" alt="Contact Us" class="contact-us-image" />
            <div>商务合作</div>
          </a>

        </div>
        <div class="about-info">
          <a href="https://t.me/semijihe" style="  text-decoration: none;color: #ffffff">
            <img src="../assets/TG.png" alt="About Us" class="about-us-image" />
            <div>官方讨论群</div>
          </a>
        </div>
      </div>
    </div>

 
    <div class="middle-section">
      <div class="carousel"> 
        
          <img :src="imgurl" class="main-img" />
          <img :src="imgurlaa" class="floating-img"  >  
          
      </div>
      <div class="members"> 
        <img src="../assets/a10.png" class="member-image" />
        <img src="../assets/a2.png" class="member-image" />
        <img src="../assets/a3.png" class="member-image" />
        <img src="../assets/a4.png" class="member-image" />
        <img src="../assets/a5.png" class="member-image" />
        <img src="../assets/a6.png" class="member-image" />
        <img src="../assets/a7.png" class="member-image" />
        <img src="../assets/a8.png" class="member-image" />
        <img src="../assets/a9.png" class="member-image" />
        <img src="../assets/a1.png" class="member-image" />
        <img src="../assets/a11.png" class="member-image" />
        <img src="../assets/a12.png" class="member-image" />
      </div>
    </div>


    <div class="text-content"></div>

    <!-- 底部布局 -->
    <div class="bottom-section">
      <div class="android-download" @click="downloadApp">
        <img src="../assets/anzhuo_an.png" class="downloadimg" />
      </div>
      <div class="ios-download">
        <img src="../assets/pingguo_an.png" class="downloadimg" @click="iosTg" />
      </div>
    </div>
  </div>
</template>
  
<style scoped>
*{
  padding: 0;
  margin: 0;
  
}

.promotion-page {
     height: 98vh;
  margin: 0 auto;
  
  /* 居中显示 */
  display: flex;
  flex-direction: column;
  background-image: url('../assets/bg01.png');
  /* 替换为你的背景图片路径 */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 10px;


}

.text-content {

  text-align: center;
  font-size: 16px;
  color: rgb(231 106 127);
  padding-top: 15px;
  font-style: italic;
  font-weight: normal;
  margin-bottom: 2vh;

}

.carousel{ 
  position: relative;
      
        margin: 0 auto;

}
.carousel-image {
  max-width: 100%;
  float: right; 
  height: 380px;
}
.main-img {
  max-width: 100%;
  height: 380px;
  position: relative;
        z-index: 1;
    }
    .floating-img {
      
      position: absolute;
        top: 50%;
        left: 50%; /* 左侧偏移50% */
        transform: translateX(-50%); /* 通过transform将图片水平居中 */
        z-index: 1; /* 设置浮动图片的堆叠顺序，比主要图片低 */
         max-width: 50%; 
    }

.downloadimg {
  max-width: 100%;
  height: auto;
}

.member-image {
  max-width: 20%;
  height: auto;
  padding: 1px;
}

.top-section {
  display: flex;
  justify-content: space-between;

  align-items: center;

}

.logoimg {
  max-width: 80%;
  height: auto;
}

.right-section {
  display: flex;
  height: 10vh;
  margin-top: 10px;
  font-size: 16px;
  color: rgb(231 106 127);
}

.contact-info,
.about-info {
  text-align: center;
  padding-left: 10px;

}

.contact-us-image,
.about-us-image {
  max-width: 100%;
  height: auto;
  height: 5vh;
}

.middle-section {}



.members {
  padding-top: 10px;
}


.bottom-section {
  max-width: 800px;
  margin: 0 auto;
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;

}



.android-download,
.ios-download {}
</style>
  
<script>
import { ref } from 'vue'
import router from '@/router/index'
import { useRoute } from 'vue-router' 
import axios from 'axios';

export default {
  name: 'indexView',

  setup() {


    const images = [
      "/banner01.png",
      "/banner02.png",
    ];

    var currentIndex = 0;

    const imgurl=ref("");
    const imgurlaa=ref("");

    
    const currentImage = () => {
      imgurl.value=  images[currentIndex];
      imgurlaa.value=currentIndex==0?"aa.png":"xyou.png";
    }

    currentImage();

    const   startCarousel = () => {
      setInterval(() => {
        currentIndex = (currentIndex + 1) % images.length;
        currentImage();
      }, 3000); 
    };
    startCarousel();

    const route = useRoute()

 
    const iosTg = () => {
      var queryString = window.location.search;
      router.push('/ios' + queryString);
    }

    const downloadApp = () => {
      var queryString = window.location.search;
      if(queryString){
        window.location.href = "/dowd" + queryString+"&type=1"
      }else{
        window.location.href = "/dowd?type=1"
      }
     
    }

    const getHttp=()=>{
      var queryString = window.location.search;
      if (queryString.length==0) {
        queryString="?type=1"
      }else{
        queryString=queryString+"&type=1"
      }
      axios.post('/api/addVisit'+queryString)
      .then(response => {
        // Handle successful response
     
      })
      .catch(error => {
        // Handle error
       
      }); 
    }
 
    getHttp();

    return {
      iosTg,
      downloadApp,
      imgurl,
      imgurlaa,
    }
  }



}
</script>
  